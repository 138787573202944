<template>
  <v-dialog v-model="dialog" width="80vw">
    <v-app id="inspire" style="overflow: hidden">
      <v-card-title>
        <div class="d-flex justify-center font-weight-bold">
          <v-icon left color="yellow">mdi-file-multiple</v-icon>
          <h2>Select a program template</h2>
        </div>
        <div class="ml-auto">
          <v-btn icon x-large @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </div>
      </v-card-title>
      <!-- <v-divider></v-divider> -->
      <v-container v-if="!isShareCode" class="ma-0 pa-0">
        <v-tabs v-model="tab">
          <v-tab class="font-weight-bold">All</v-tab>
          <v-tab class="font-weight-bold"
            ><v-icon color="yellow" left>mdi-star</v-icon>Private</v-tab
          >
          <v-tab class="font-weight-bold"><v-icon color="green" left>mdi-city</v-icon>Public</v-tab>
          <v-tab class="font-weight-bold ml-auto mr-6" @click="createNewTemplate"
            >+ Make Template</v-tab
          >
        </v-tabs>
        <v-row>
          <v-col v-for="template in filteredTemplates" :key="template.templateName" cols="6">
            <template>
              <v-card class="d-flex flex-wrap my-card" outlined>
                <v-row>
                  <v-col cols="auto">
                    <v-avatar>
                      <v-img
                        src="https://f.hubspotusercontent00.net/hubfs/2480959/P_Cutoff3-Oct-07-2020-10-55-09-87-AM.png"
                      ></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <v-row class="pa-2">
                      <v-col>
                        <div class="title__templateName">{{ template.templateName }}</div>
                        <!-- <div class="mt-5">
                          <v-chip color="purple" class="template-chip white--text"
                            >BY EMPLOYERS</v-chip
                          ><v-chip color="green" class="template-chip white--text ml-1"
                            >FOR STUDENTS</v-chip
                          ><v-chip class="template-chip black--text ml-1">4 HOURS SETUP</v-chip>
                        </div> -->
                      </v-col>
                    </v-row>
                    <!-- <v-row>
                      <v-col>
                        <div class="description">
                          {{ template.templateDesc }}
                        </div>
                      </v-col>
                    </v-row> -->
                    <v-row>
                      <!-- <v-col>
                        <v-btn class="btn" text>Learn More</v-btn>
                      </v-col> -->
                      <v-col>
                        <v-btn
                          class="btn font-weight-bold"
                          dark
                          depressed
                          rounded
                          x-large
                          color="primary"
                          :loading="isSelected && isSelectedTemplateId === template._id.toString()"
                          @click="handleSelect(template._id.toString())"
                          >Select</v-btn
                        >
                      </v-col>
                    </v-row>
                    <!-- <v-divider class="my-3"></v-divider> -->
                    <v-row class="ml-auto" align="center">
                      <v-col>
                        <v-icon v-if="!template.isExpanded" @click="template.isExpanded = true"
                          >mdi-chevron-down</v-icon
                        >
                        <v-icon v-if="template.isExpanded" @click="template.isExpanded = false"
                          >mdi-chevron-up</v-icon
                        >
                        <span class="ml-1 overline font-weight-black grey--text">Expand</span>
                      </v-col>
                    </v-row>
                    <v-row v-if="template.isExpanded">
                      <v-col v-for="(adk, index) in template.adks" :key="index" class="mt-4">
                        <v-chip small rounded>{{ adk }}</v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </template>
            <!-- <v-divider class="my-6"></v-divider> -->
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="isShareCode" class="ma-0 pa-0">
        <v-tabs v-model="tab">
          <v-tab class="font-weight-bold">All</v-tab>
          <v-tab disabled class="font-weight-bold ml-auto mr-6" @click="createNewTemplate"
            >+ Make Template</v-tab
          >
        </v-tabs>
        <v-row>
          <v-col v-for="template in filterSelectedTemplates" :key="template.templateName" cols="6">
            <template>
              <v-card class="d-flex flex-wrap my-card" outlined>
                <v-row>
                  <v-col cols="auto">
                    <v-avatar>
                      <v-img
                        src="https://f.hubspotusercontent00.net/hubfs/2480959/P_Cutoff3-Oct-07-2020-10-55-09-87-AM.png"
                      ></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <v-row class="pa-2">
                      <v-col>
                        <div class="title__templateName">{{ template.templateName }}</div>
                        <!-- <div class="mt-5">
                          <v-chip color="purple" class="template-chip white--text"
                            >BY EMPLOYERS</v-chip
                          ><v-chip color="green" class="template-chip white--text ml-1"
                            >FOR STUDENTS</v-chip
                          ><v-chip class="template-chip black--text ml-1">4 HOURS SETUP</v-chip>
                        </div> -->
                      </v-col>
                    </v-row>
                    <!-- <v-row>
                      <v-col>
                        <div class="description">
                          {{ template.templateDesc }}
                        </div>
                      </v-col>
                    </v-row> -->
                    <v-row>
                      <!-- <v-col>
                        <v-btn class="btn" text>Learn More</v-btn>
                      </v-col> -->
                      <v-col>
                        <v-btn
                          class="btn font-weight-bold"
                          dark
                          depressed
                          rounded
                          x-large
                          color="primary"
                          :loading="isSelected && isSelectedTemplateId === template._id.toString()"
                          @click="handleSelect(template._id.toString())"
                          >Select</v-btn
                        >
                      </v-col>
                    </v-row>
                    <!-- <v-divider class="my-3"></v-divider> -->
                    <v-row class="ml-auto" align="center">
                      <v-col>
                        <v-icon v-if="!template.isExpanded" @click="template.isExpanded = true"
                          >mdi-chevron-down</v-icon
                        >
                        <v-icon v-if="template.isExpanded" @click="template.isExpanded = false"
                          >mdi-chevron-up</v-icon
                        >
                        <span class="ml-1 overline font-weight-black grey--text">Expand</span>
                      </v-col>
                    </v-row>
                    <v-row v-if="template.isExpanded">
                      <v-col v-for="(adk, index) in template.adks" :key="index" class="mt-4">
                        <v-chip small rounded>{{ adk }}</v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </template>
            <!-- <v-divider class="my-6"></v-divider> -->
          </v-col>
        </v-row>
      </v-container>
    </v-app>
    <!-- <save-document-dialog
      :save-document-dialog="saveDocumentDialog"
      :document-type="documentType"
      :program-name-value="programNameValue"
      :is-save-document="isSaveDocument"
      @close-dialog="(saveDocumentDialog = false), (isSelectedTemplateId = '')"
      @save-document="saveDocument"
    ></save-document-dialog> -->
  </v-dialog>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api';
import store, { useUserState, useUserActions } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';
import { required } from 'vee-validate/dist/rules';

export default {
  name: 'TemplateCollection',
  // components: {
  //   SaveDocumentDialog: () => import('@/components/molecules/m-save-document-dialog.vue')
  // },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    isFromAutoSetup: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedScopeData: {
      type: Object,
      required: false,
      default: () => ({})
    },
    selectedScopeBulkData: {
      type: Array,
      required: false,
      default: () => []
    },
    bulkCreate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, ctx) {
    const programNameValue = ref('');
    // const saveDocumentDialog = ref(false);
    const { user } = useUserState(['user']);
    const documentType = ref('program');
    const isSaveDocument = ref(false);
    const isShareCode = ref(false);
    const issponsorLink = ref(false);
    const filterSelectedTemplates = ref([]);
    const dialog = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const {
      cloneProgram,
      getSharableUrlByName,
      fetchTemplates,
      cloneTemplate,
      createTemplate,
      createAutomationProgram
    } = useUserActions([
      'cloneProgram',
      'getSharableUrlByName',
      'fetchTemplates',
      'cloneTemplate',
      'createTemplate',
      'createAutomationProgram'
    ]);
    const tab = ref(2);
    const templates = ref([]);
    const filteredTemplates = computed(() => {
      switch (tab.value) {
        case 1:
          return templates.value.filter(
            template => template.visibility && template.visibility === 'private'
          );
        case 2:
          return templates.value.filter(
            template => template.visibility && template.visibility === 'public'
          );
        default:
          return templates.value;
      }
    });

    const openInNewTab = link => {
      window.open(link, '_blank');
    };

    const isSelected = ref(false);
    const isSelectedTemplateId = ref();

    const getTemplates = async () => {
      const response = await fetchTemplates();
      templates.value = response.map(data => ({ ...data, isExpanded: false }));
      const isSponsorRoute =
        ctx.root.$route.path.startsWith('/sponsor/') && ctx.root.$route.params?.shareCode;
      if (isSponsorRoute) {
        console.log('Inner>>');
        const programSettings = localStorage.getItem('programSettings');
        if (programSettings) {
          issponsorLink.value = true;
          const { includeSelfCreationOfPrograms, selectedSelfCreationProgram } =
            JSON.parse(programSettings);
          if (
            includeSelfCreationOfPrograms &&
            selectedSelfCreationProgram &&
            selectedSelfCreationProgram.length > 0
          ) {
            isShareCode.value = true;
            tab.value = 0;
            filterSelectedTemplates.value = templates.value.filter(template =>
              selectedSelfCreationProgram.includes(template.templateName)
            );
            console.log('fileterTemplate', filterSelectedTemplates.value);
          } else {
            isShareCode.value = false;
          }
        } else {
          isShareCode.value = false;
        }
      } else {
        isShareCode.value = false;
        issponsorLink.value = false;
      }
    };

    const createNewTemplate = async () => {
      const response = await createTemplate();
      if (response) {
        ctx.root.$router.push({
          name: 'templateSetup',
          params: {
            templateId: response._id.toString(),
            page: 0
          }
        });
      }
    };

    const generateUniqueProgramName = async () => {
      const uniqueId = Date.now() + Math.floor(Math.random() * 1000);
      const uniqueProgramName = `Program ${uniqueId}`;
      return uniqueProgramName;
    };

    onMounted(() => {
      getTemplates();
    });

    const saveDocument = async data => {
      // const { type, nameValue } = data;
      const uniqueProgramName = await generateUniqueProgramName();
      programNameValue.value = uniqueProgramName;
      isSaveDocument.value = true;
      const response = await cloneProgram({
        program_template_id: isSelectedTemplateId?.value,
        programName: programNameValue?.value,
        sharable_url: await getSharableUrlByName({
          name: programNameValue?.value,
          type: 'program'
        })
      });
      if (response?.error?.description === 'Program Already exists with this name!') {
        isSaveDocument.value = false;
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: response.error.description,
          type: 'error',
          isShowSnackbar: true
        });
      } else if (response) {
        isSaveDocument.value = false;
        ctx.root.$router.push({
          name: 'guide',
          params: {
            programId: response._id.toString(),
            page: 0
          }
        });
        // await props.fetchProgram();
      }
      isSelectedTemplateId.value = '';
      programNameValue.value = '';
    };

    const handleSelect = async templateId => {
      isSelectedTemplateId.value = templateId;
      isSelected.value = true;
      // const response = await cloneTemplate({ program_template_id: templateId });
      // if (response) {
      //   isSelected.value = false;
      //   ctx.root.$router.push({
      //     name: 'templateSetup',
      //     params: {
      //       templateId: response._id.toString(),
      //       page: 0
      //     }
      //   });
      // }
      console.log('props.isFromAutoSetup', props.isFromAutoSetup);
      if (!props.isFromAutoSetup) {
        // saveDocumentDialog.value = true;
        saveDocument();
      } else {
        console.log('templateId : ', templateId);
        // close in this condition
        // dialog.value = false;
        console.log(props.selectedScopeData, props.bulkCreate);
        const queryParams = new URLSearchParams(window.location.search);
        if (props.selectedScopeData && !props.bulkCreate) {
          const newProgram = props.selectedScopeData;
          newProgram.program_template_id = templateId;
          if (issponsorLink.value) {
            const sponsorAllData = localStorage.getItem('sponsorAllData');
            if (sponsorAllData) {
              const { original_id } = JSON.parse(sponsorAllData);
              newProgram.organizers = {
                student_id: user?.value?._id.toString(),
                sponsor_id: original_id
              };
            }
          }
          const programData = await createAutomationProgram(newProgram);
          isSelected.value = false;
          if (queryParams.has('email') && queryParams.has('password')) {
            const email = queryParams.get('email');
            const password = queryParams.get('password');
            const domain = window.location.origin;
            window.location.href = `${domain}/admin-program-link?email=${email}&password=${password}`;
            store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
              message: 'Program created successfully',
              type: 'success',
              isShowSnackbar: true
            });
          }
          if (programData) {
            ctx.root.$router.push(
              `/guide/${programData?.insertedId?.toString()}/0?is_from_auto_setup=true`
            );
          } else {
            console.log('Error ');
          }
        } else {
          const newProgram = props.selectedScopeBulkData;
          newProgram.forEach(async (scope, index) => {
            const updatedScope = { ...scope, program_template_id: templateId };
            const programData = await createAutomationProgram(updatedScope);
            isSelected.value = false;
            if (queryParams.has('email') && queryParams.has('password')) {
              const email = queryParams.get('email');
              const password = queryParams.get('password');
              const domain = window.location.origin;
              window.location.href = `${domain}/admin-program-link?email=${email}&password=${password}`;
              store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
                message: 'Program created successfully',
                type: 'success',
                isShowSnackbar: true
              });
            }
            if (programData) {
              const routeUrl = `/guide/${programData?.insertedId?.toString()}/0?is_from_auto_setup=true`;
              if (index === 0) {
                ctx.root.$router.push(
                  `/guide/${programData?.insertedId?.toString()}/0?is_from_auto_setup=true`
                );
              } else {
                setTimeout(() => {
                  window.open(routeUrl, '_blank');
                }, index * 500); // Delay increases based on index
              }
            } else {
              console.log('Error ');
            }
          });
        }
      }
    };

    return {
      templates,
      filteredTemplates,
      openInNewTab,
      isSelected,
      isSelectedTemplateId,
      handleSelect,
      tab,
      dialog,
      createNewTemplate,
      // saveDocumentDialog,
      documentType,
      programNameValue,
      isSaveDocument,
      saveDocument,
      isShareCode,
      filterSelectedTemplates
    };
  }
};
</script>

<style>
.my-card {
  margin: 16px;
  padding: 16px;
}

.title {
  font-size: 20px;
  font-weight: bold;
}

.title__templateName {
  font-size: 20px;
  font-weight: 800;
  font-family: Raleway;
  /* text-transform: uppercase; */
}

.sub-title {
  font-size: 14px;
  margin-top: 8px;
}

.description {
  font-size: 14px;
  /* margin-top: 16px; */
  font-family: Raleway;
}

.btn {
  margin-top: 16px;
}

.details {
  font-size: 16px;
  cursor: pointer;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chip {
  margin-right: 8px;
  margin-bottom: 8px;
}
.rotate {
  transform: rotate(180deg);
}
</style>
