import * as Airtable from 'airtable';

const base = new Airtable({
  apiKey: 'pattCKvlVPibAMZw1.6eb525b7643d3b8c59cfed53e7dd58128f181dca3f4a155ce614303fa1a29329'
}).base('appo8g9TjuRyPjwA9');

export const employersTable = base('Companies');

export const savedScopesTable = base('Saved Scopes');

export const organizationsTable = base('Organization');

export const programsTable = base('Program');

const participantsTable = base('Student');

export interface Employer {
  id: string;
  name: string;
  industry: string;
  website: string;
  shortDescription: string;
  employeeCount: string;
  schoolDistrict: string;
  pathways: string[];
  moreNotes: string;
  rank: number;
  jurisdiction: string;
}

export interface SavedScope {
  id?: string;
  userId: string;
  email?: string;
  deviceInfo?: {
    id: string;
    browser: string;
    platform: string;
    language: string;
    timeZone: string;
    firstSeen: string;
  };
  savedAt: string;
  savedInputs: Record<string, string>;
  scopesList: Array<any>;
  scopesPerEmployer: number;
  selectedScopes: Array<any>;
  name?: string;
}

export interface Organization {
  id: string;
  title: string;
  domain: string;
  domains: string[];
  organizationJoinLink: string;
  users: number;
  participants: number;
  programs: number;
  _id: string | null;
  members: number;
}

export interface Program {
  id: string;
  programName: string;
  programDesc: string;
  domains: string;
  participants: string;
}

export interface Participant {
  id: string;
  fullName: string;
  programName: string;
  programDomains: string;
  programDesc: string;
  schoolDistrict: string;
  domainOnly: string;
}

export async function fetchEmployers(options = {}): Promise<Employer[]> {
  const { searchQuery = '', pageSize = 100, offset = null } = options;

  let filterFormula = '';
  if (searchQuery) {
    const searchTerms = searchQuery
      .toLowerCase()
      .split(' ')
      .filter(term => term.length > 0);

    // Create the main filter that requires at least one term to match
    const mainFilter = searchTerms.map(
      term => `OR(
        SEARCH('${term}', LOWER({Company Name})),
        SEARCH('${term}', LOWER({Industry})),
        SEARCH('${term}', LOWER({Website})),
        SEARCH('${term}', LOWER({Short Description})),
        SEARCH('${term}', LOWER({School District})),
        SEARCH('${term}', LOWER({Pathways})),
        SEARCH('${term}', LOWER({More Notes})),
        SEARCH('${term}', LOWER({Jurisdiction}))
      )`
    );

    filterFormula = `AND(${mainFilter.join(',')})`;
  }

  try {
    const selectOptions: any = {
      view: 'Official',
      maxRecords: 10000,
      filterByFormula: filterFormula
    };

    if (typeof offset === 'number') {
      selectOptions.offset = offset;
    }

    const records = await employersTable.select(selectOptions).all();

    if (searchQuery) {
      const searchTerms = searchQuery
        .toLowerCase()
        .split(' ')
        .filter(term => term.length > 0);

      // Map records and calculate match count
      const recordsWithRank = records.map(record => {
        const allFields = [
          record.get('Company Name') as string,
          record.get('Industry') as string,
          record.get('Website') as string,
          record.get('Short Description') as string,
          record.get('School District') as string,
          (record.get('Pathways') as string[])?.join(' ') || '',
          record.get('More Notes') as string,
          record.get('Jurisdiction') as string
        ]
          .join(' ')
          .toLowerCase();

        // Count how many search terms match
        const matchCount = searchTerms.filter(term => allFields.includes(term)).length;

        return {
          id: record.id,
          name: record.get('Company Name') as string,
          industry: record.get('Industry') as string,
          jurisdiction: record.get('Jurisdiction') as string,
          website: record.get('Website') as string,
          matchCount
        };
      });

      // Sort by match count (descending) and then by name
      return recordsWithRank.sort((a, b) => {
        if (a.matchCount !== b.matchCount) {
          return b.matchCount - a.matchCount;
        }
        return a.name.localeCompare(b.name);
      });
    }

    return records.map(record => {
      const jurisdiction = record.get('Jurisdiction');
      console.log('Raw jurisdiction value:', jurisdiction);

      return {
        id: record.id,
        name: record.get('Company Name') as string,
        industry: record.get('Industry') as string,
        website: record.get('Website') as string,
        shortDescription: record.get('Short Description') as string,
        jurisdiction: jurisdiction ? String(jurisdiction) : '',
        pathways: (record.get('Pathways') as string[]) || [],
        employeeCount: record.get('Employee Count') as string,
        schoolDistrict: record.get('School District') as string,
        moreNotes: record.get('More Notes') as string,
        rank: record.get('Rank') as number
      };
    });
  } catch (error) {
    console.error('Error fetching employers:', error);
    throw error;
  }
}

export async function saveScopesToAirtable(data: SavedScope) {
  try {
    const fields = {
      'User ID': data.userId,
      Email: data.email,
      'Device Info': data.deviceInfo ? JSON.stringify(data.deviceInfo) : '',
      'Saved At': data.savedAt,
      'Saved Inputs': JSON.stringify(data.savedInputs),
      'Scopes List': JSON.stringify(data.scopesList),
      'Scopes Per Employer': data.scopesPerEmployer,
      'Selected Scopes': JSON.stringify(data.selectedScopes),
      Name: data.name || `Save from ${new Date(data.savedAt).toLocaleDateString()}`
    };

    const record = await savedScopesTable.create([{ fields }]);
    return record[0];
  } catch (error) {
    console.error('Error saving to Airtable:', error);
    throw error;
  }
}

export async function loadScopesFromAirtable(userId: string): Promise<SavedScope[]> {
  try {
    const records = await savedScopesTable
      .select({
        filterByFormula: `{User ID} = '${userId}'`,
        sort: [{ field: 'Saved At', direction: 'desc' }]
      })
      .all();

    return records.map(record => ({
      id: record.id,
      userId: (record.get('User ID') as string) || '',
      savedAt: (record.get('Saved At') as string) || '',
      savedInputs: record.get('Saved Inputs')
        ? JSON.parse(record.get('Saved Inputs') as string)
        : {},
      scopesList: record.get('Scopes List') ? JSON.parse(record.get('Scopes List') as string) : [],
      scopesPerEmployer: (record.get('Scopes Per Employer') as number) || 1,
      selectedScopes: record.get('Selected Scopes')
        ? JSON.parse(record.get('Selected Scopes') as string)
        : [],
      name: (record.get('Name') as string) || '',
      email: (record.get('Email') as string) || '',
      deviceInfo: record.get('Device Info') ? JSON.parse(record.get('Device Info') as string) : null
    }));
  } catch (error) {
    console.error('Error loading from Airtable:', error);
    throw error;
  }
}

export async function migrateDeviceSaves(deviceId: string, userId: string) {
  try {
    const deviceSaves = await loadScopesFromAirtable(deviceId);

    // Use Promise.all with map instead of for...of loop
    await Promise.all(
      deviceSaves.map(save =>
        saveScopesToAirtable({
          ...save,
          userId
        })
      )
    );

    return true;
  } catch (error) {
    console.error('Error migrating device saves:', error);
    throw error;
  }
}

export async function fetchOrganizations(options = {}): Promise<Organization[]> {
  const { searchQuery = '', searchField = 'title', pageSize = 100, offset = null } = options;

  let filterFormula = '';
  if (searchQuery) {
    if (searchField === 'domain') {
      filterFormula = `OR(${searchQuery
        .split(' ')
        .map(term => `SEARCH('${term}', LOWER({Domain}))`)
        .join(', ')})`;
    } else {
      filterFormula = `OR(${searchQuery
        .split(' ')
        .map(term => `SEARCH('${term}', LOWER({Title}))`)
        .join(', ')})`;
    }
  }

  try {
    const selectOptions: any = {
      view: 'Official',
      maxRecords: 1000,
      filterByFormula: filterFormula
    };

    if (typeof offset === 'number') {
      selectOptions.offset = offset;
    }

    const records = await organizationsTable.select(selectOptions).all();

    return records.map(record => ({
      id: record.id,
      title: record.get('Title') as string,
      domain: record.get('Domain') as string,
      domains: (record.get('Domains') as string[]) || [],
      organizationJoinLink: record.get('Organization Join Link') as string,
      users: (record.get('Users') as number) || 0,
      participants: (record.get('Participants') as number) || 0,
      programs: (record.get('Programs') as number) || 0,
      _id: (record.get('_id') as string) || null,
      members: (record.get('Members') as number) || 0
    }));
  } catch (error) {
    console.error('Error fetching organizations:', error);
    throw error;
  }
}

export async function fetchPrograms(): Promise<Program[]> {
  try {
    const records = await programsTable
      .select({
        view: 'Official'
      })
      .all();

    return records.map(record => ({
      id: record.id,
      programName: record.get('programName') as string,
      programDesc: record.get('programDesc') as string,
      domains: record.get('Domains (Formula)') as string,
      participants: record.get('Count (Student) - All Years') as string
    }));
  } catch (error) {
    console.error('Error fetching programs:', error);
    throw error;
  }
}

export async function fetchParticipants(): Promise<Participant[]> {
  try {
    const records = await participantsTable
      .select({
        view: 'Official'
      })
      .all();

    return records.map(record => ({
      id: record.id,
      fullName: record.get('ADMIN - Full Name') as string,
      programName: record.get('programName') as string,
      programDomains: ((record.get('Domains (Formula) (from Program)') as string[]) || []).join(
        ', '
      ),
      programDesc: ((record.get('programDesc (from Program)') as string[]) || []).join(', '),
      schoolDistrict: (
        (record.get('portfolio.school.district (from StudentPortfolio)') as string[]) || []
      ).join(', '),
      domainOnly: record.get('Domain Only (Stakeholders (Link to User))') as string,
      tderCount: Array.isArray(
        record.get('Count (TDER (Link to Student)) (from TDER (Link to User))')
      )
        ? record.get('Count (TDER (Link to Student)) (from TDER (Link to User))')[0] || 0
        : (record.get('Count (TDER (Link to Student)) (from TDER (Link to User))') as number),
      gender: record.get('Gender') as string,
      language: record.get('portfolio.home.language (from StudentPortfolio)') as string,
      grade: record.get('portfolio.grade (from StudentPortfolio)') as string,
      pathways: record.get('pathways (from adk.start) (from Program)') as string,
      school: record.get('portfolio.school.name (from StudentPortfolio)') as string,
      zipcode: record.get('portfolio.home.zipcode (from StudentPortfolio)') as string,
      city: record.get('portfolio.home.city (from StudentPortfolio)') as string,
      ethnicity: record.get('portfolio.ethnicity (from StudentPortfolio)') as string,
      birthYear: record.get('birthDate - YEAR') as string,
      percentageCompleted: record.get('percentageCompleted') as string
    }));
  } catch (error) {
    console.error('Error fetching participants:', error);
    return [];
  }
}

export async function fetchParticipantsByDomain(domain: string): Promise<Participant[]> {
  try {
    const filterFormula = `SEARCH('${domain}', {Domains (Formula) (from Program)})`;

    const records = await participantsTable
      .select({
        view: 'Official'
        // filterByFormula: filterFormula
      })
      .all();

    return records.map(record => ({
      id: record.id,
      fullName: record.get('ADMIN - Full Name') as string,
      programName: record.get('programName') as string,
      programDomains: record.get('Domains (Formula) (from Program)') as string,
      schoolDistrict: record.get('portfolio.school.district (from StudentPortfolio)') as string
    }));
  } catch (error) {
    console.error('Error fetching participants by domain:', error);
    throw error;
  }
}

export async function fetchOptionsByType(type: string) {
  const records = await base('Qualification')
    .select({
      view: 'Official',
      filterByFormula: `{Type} = "${type}"`
    })
    .all();

  return records.map(record => record.fields.Name); // Assuming 'Name' is the field with the options
}

export async function fetchParticipantsByFilters(filters: {
  domain?: string;
  schoolDistrict?: string;
  pathway?: string;
  industries?: string[];
  city?: string;
  zipcode?: string;
  grade?: string;
  gender?: string;
  language?: string[];
  ethnicity?: string[];
  birthYear?: string;
  percentageCompleted?: string;
  type?: string;
  organization?: string;
}): Promise<Participant[]> {
  try {
    const filterFormula = [];

    if (filters.schoolDistrict) {
      filterFormula.push(`{School District} = '${filters.schoolDistrict}'`);
    }
    if (filters.pathway) {
      filterFormula.push(`SEARCH('${filters.pathway}', {Pathways})`);
    }
    if (filters.industries && filters.industries.length > 0) {
      const industriesFilter = filters.industries
        .map(industry => `SEARCH('${industry}', {Industry})`)
        .join(', ');
      filterFormula.push(`OR(${industriesFilter})`);
    }
    if (filters.city) {
      filterFormula.push(`{City} = '${filters.city}'`);
    }
    if (filters.zipcode) {
      filterFormula.push(`{Zipcode} = '${filters.zipcode}'`);
    }
    if (filters.grade) {
      filterFormula.push(`{Grade} = '${filters.grade}'`);
    }
    if (filters.gender) {
      filterFormula.push(`{Gender} = '${filters.gender}'`);
    }
    if (filters.language && filters.language.length > 0) {
      const languageFilter = filters.language
        .map(lang => `SEARCH('${lang}', {Language})`)
        .join(', ');
      filterFormula.push(`OR(${languageFilter})`);
    }
    if (filters.ethnicity && filters.ethnicity.length > 0) {
      const ethnicityFilter = filters.ethnicity
        .map(eth => `SEARCH('${eth}', {Ethnicity})`)
        .join(', ');
      filterFormula.push(`OR(${ethnicityFilter})`);
    }
    if (filters.birthYear) {
      filterFormula.push(`{Birth Year} = '${filters.birthYear}'`);
    }
    if (filters.percentageCompleted) {
      filterFormula.push(`{percentageCompleted} = '${filters.percentageCompleted}'`);
    }
    if (filters.type) {
      filterFormula.push(`{Type} = '${filters.type}'`);
    }
    if (filters.organization) {
      filterFormula.push(`{Organization} = '${filters.organization}'`);
    }

    const formula = filterFormula.length > 0 ? `AND(${filterFormula.join(', ')})` : '';

    console.log('Filter formula:', formula); // Log the filter formula

    const records = await participantsTable
      .select({
        view: 'Official',
        filterByFormula: formula
      })
      .all();

    console.log('Fetched records before domain filter:', records.length); // Log the number of fetched records

    // Filter records by domain in JavaScript
    const filteredRecords = records.filter(record => {
      const domainField = record.get('Domains (Formula) (from Program)') as string[];
      console.log('Domain field raw value:', domainField); // Log the raw domain field value

      // Ensure domainField is an array
      const domains = Array.isArray(domainField) ? domainField : [];

      console.log('Processed domains:', domains); // Log the processed domains

      const includesDomain = domains.includes(filters.domain);
      console.log(`Does domains include '${filters.domain}'?`, includesDomain); // Log the inclusion check

      return includesDomain;
    });

    console.log('Fetched records after domain filter:', filteredRecords.length); // Log the number of filtered records

    return filteredRecords.map(record => ({
      id: record.id,
      fullName: record.get('ADMIN - Full Name') as string,
      programName: record.get('programName') as string,
      programDesc: record.get('programDesc (from Program)') as string,
      programDomains: record.get('Domains (Formula) (from Program)') as string,
      schoolDistrict: record.get('portfolio.school.district (from StudentPortfolio)') as string,
      gender: record.get('portfolio.gender (from StudentPortfolio)') as string,
      language: record.get('portfolio.home.language (from StudentPortfolio)') as string,
      grade: record.get('portfolio.grade (from StudentPortfolio)') as string,
      pathways: record.get('pathways (from adk.start) (from Program)') as string,
      school: record.get('portfolio.school.name (from StudentPortfolio)') as string,
      zipcode: record.get('portfolio.home.zipcode (from StudentPortfolio)') as string,
      city: record.get('portfolio.home.city (from StudentPortfolio)') as string,
      ethnicity: record.get('portfolio.ethnicity (from StudentPortfolio)') as string,
      birthYear: record.get('birthDate - YEAR') as string,
      percentageCompleted: record.get('Percentage Completed') as string
    }));
  } catch (error) {
    console.error('Error fetching participants with filters:', error);
    throw error;
  }
}
