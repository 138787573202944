


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  defineComponent,
  computed,
  ref,
  onMounted,
  onUnmounted,
  reactive,
  nextTick,
  getCurrentInstance
} from '@vue/composition-api';
import { Breakpoint } from 'vuetify/lib/services/breakpoint';
import { preset } from 'vuetify/lib/presets/default';
import { readFileSync } from 'fs';
import axios from 'axios';
import store, { useUserState } from '../../../../store';
import { ActionTypes } from '../../../../store/modules/tools/actions';
import {
  saveScopesToAirtable,
  loadScopesFromAirtable,
  SavedScope
} from '../../../../services/airtable';

const generateDeviceId = () => {
  let deviceId = localStorage.getItem('device_id');
  if (!deviceId) {
    deviceId = `device_${Math.random().toString(36).substr(2, 9)}`;
    localStorage.setItem('device_id', deviceId);
  }
  return deviceId;
};

const getDeviceInfo = () => {
  return {
    id: generateDeviceId(),
    browser: navigator.userAgent,
    platform: navigator.platform,
    language: navigator.language,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    firstSeen: new Date().toISOString()
  };
};

export default defineComponent({
  name: 'SetupScopesWizard',
  props: {
    website: {
      required: true,
      type: String
    },
    scopes: {
      required: true,
      type: Array as () => string[]
    },
    loading: {
      required: true,
      type: Boolean
    },
    value: {
      required: true,
      type: String
    }
  },
  setup(props, context) {
    const { user }: any = useUserState(['user']);
    const { emit } = context;
    const scopeRefs = ref();
    const customScopeRef = ref();
    const scope__containerRef = ref();
    const editingCustomScope = ref(false);
    const gptLoading = ref(false);
    const customScopeTitle = ref('');
    const customScopeDetails = ref('');
    const isShareCode = ref(false);
    const instance = getCurrentInstance();
    const root = instance?.proxy;
    const disabledCreateProgram = ref(false);
    const customScope = computed<string>({
      get: () => {
        const title = customScopeTitle.value || '';
        const details = customScopeDetails.value || '';
        return title + (title && details ? '.' : '') + details;
      },
      set: value => {
        const sentences = value?.split('.');
        customScopeTitle.value = sentences?.shift() || '';
        customScopeDetails.value = sentences?.join('.');
      }
    });

    const displayScopes = reactive(
      props.scopes.map(scope => {
        const scopeSentences = scope.split('.');
        return {
          base: scope,
          title: scopeSentences[0],
          details: scopeSentences.slice(1).join('.')
        };
      })
    );
    if (!props.value) {
      disabledCreateProgram.value = true;
    }

    const dialogInputs = ref({
      strengths: '',
      passions: '',
      problems: '',
      jobs: '',
      careers: '',
      skills: '',
      tools: '',
      deliverables: '',
      goals: '',
      user: '',
      upload: ''
    });

    const savedInputs = ref({
      strengths: localStorage.getItem('scope_strengths') || '',
      passions: localStorage.getItem('scope_passions') || '',
      problems: localStorage.getItem('scope_problems') || '',
      jobs: localStorage.getItem('scope_jobs') || '',
      careers: localStorage.getItem('scope_careers') || '',
      skills: localStorage.getItem('scope_skills') || '',
      tools: localStorage.getItem('scope_tools') || '',
      deliverables: localStorage.getItem('scope_deliverables') || '',
      goals: localStorage.getItem('scope_goals') || '',
      user: localStorage.getItem('scope_user') || '',
      upload: localStorage.getItem('scope_upload') || ''
    });

    const savedInputsFlags = ref({
      strengths: false,
      passions: false,
      problems: false,
      jobs: false,
      careers: false,
      skills: false,
      tools: false,
      deliverables: false,
      goals: false,
      user: false,
      upload: false
    });

    const createProgramDialog = ref(props.loading);
    /** --- BEGIN EVENT EMITTERS --- */
    function selectScope(content) {
      emit('input', content);
      const refs = [customScopeRef.value, ...scopeRefs.value];
      const targetRef = refs.find(vref => {
        return vref.innerText.split('\n').join('. ') === content;
      });
      targetRef?.focus();
    }
    /** --- BEGIN HELPERS --- */
    const loadingDataValue = ref({
      value: 0,
      message: 'Creating your program'
    });

    async function loadingData(duration = 30, parts = 6) {
      const messages = [
        'Creating your program',
        'Prepping your program',
        'Sending selected scope',
        'Generating program details',
        'Creating program',
        'Loading Data'
      ];
      const step = duration / parts;
      for (let n = 0, i = 0; n < duration; n += step, i++) {
        if (!props.loading) break;
        // console.log(n, i);
        // eslint-disable-next-line no-await-in-loop
        await new Promise<void>(_res => {
          setTimeout(() => {
            this.loadingDataValue.value = (n / 30) * 100;
            this.loadingDataValue.message = messages[i];
            _res();
          }, step * 1000);
        });
      }
      this.loadingDataValue.value = 95;
      this.loadingDataValue.message = 'Finishing Up..';
    }
    async function submitScope() {
      if (editingCustomScope.value) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Scope not saved! Save before continuing',
          isShowSnackbar: true
        });
        return;
      }
      emit('submitScope');
      await nextTick();
      if (!props.value) {
        return;
      }
      createProgramDialog.value = true;
      await loadingData();
    }
    function editScope(content) {
      this.customScope = content;
      this.selectScope(content);
      this.editingCustomScope = true;
      disabledCreateProgram.value = false;
      if (!user?.value) {
        localStorage.setItem('customSelectedScope', customScope.value);
      } else {
        localStorage.removeItem('customSelectedScope');
      }
      requestAnimationFrame(() => {
        this.scope__containerRef.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      });
    }
    const breakpoint = ref(new Breakpoint(preset));
    const tileHeightRate = computed(() => {
      switch (breakpoint.value.name) {
        case 'xl':
          return 60 / 3;
        case 'lg':
          return 90 / 3;
        case 'md':
          return 120 / 3;
        default:
          return 150 / displayScopes.values.length;
      }
    });

    onUnmounted(() => {
      window.removeEventListener('resize', () => breakpoint.value.update());
    });

    const dialogs = ref({
      strengths: false,
      passions: false,
      problems: false,
      jobs: false,
      careers: false,
      skills: false,
      tools: false,
      deliverables: false,
      goals: false,
      user: false,
      upload: false
    });

    const openDialog = type => {
      dialogs.value[type] = true;
      dialogInputs.value[type] = savedInputs.value[type];
    };

    const closeDialog = type => {
      dialogs.value[type] = false;
      dialogInputs.value[type] = ''; // Clear input
    };

    const saveDialog = type => {
      const input = dialogInputs.value[type];
      if (input.trim()) {
        // Save to localStorage
        localStorage.setItem(`scope_${type}`, input);
        savedInputs.value[type] = input;
      } else {
        // Clear from localStorage if empty
        localStorage.removeItem(`scope_${type}`);
        savedInputs.value[type] = '';
      }
      closeDialog(type);
    };

    const clearDialog = type => {
      localStorage.removeItem(`scope_${type}`);
      savedInputs.value[type] = '';
      dialogInputs.value[type] = '';
      dialogs.value[type] = false; // Close the dialog
    };

    const clearAllDialog = ref(false);

    const hasAnySavedInputs = computed(() => {
      return Object.values(savedInputs.value).some(value => value !== '');
    });

    const hasUserSavedInputs = computed(() => {
      return Object.entries(savedInputs.value).some(
        ([key, value]) => value !== '' && !savedInputsFlags.value[key]
      );
    });

    const clearAll = () => {
      const types = [
        'strengths',
        'passions',
        'problems',
        'jobs',
        'careers',
        'skills',
        'tools',
        'deliverables',
        'goals',
        'user',
        'upload'
      ];

      types.forEach(type => {
        // Only clear if the field is not flagged as sponsors-added
        if (!savedInputsFlags.value[type]) {
          localStorage.removeItem(`scope_${type}`);
          savedInputs.value[type] = '';
          dialogInputs.value[type] = '';
        }
      });

      clearAllDialog.value = false;
    };

    const generateAIPrompt = async website => {
      const requestData = {
        websiteUrl: website.startsWith('http') ? website : `https://${website}`,
        savedInputs: savedInputs.value
      };

      try {
        const API_ENDPOINT = process.env.VUE_APP_AUTOMATE_SETUP_SERVICE;
        const resp = await axios.post(`${API_ENDPOINT}/generate-scope/promt`, requestData);
        const scopeText = resp.data.data[0];
        return scopeText;
      } catch (err) {
        console.error('Single scope generation error:', err);
        throw err;
      }
    };

    const makeScopeWithChatGpt = async (isSponsor = false) => {
      try {
        if (isSponsor && localStorage.getItem('customSelectedScope')) {
          customScope.value = localStorage.getItem('customSelectedScope');
          editingCustomScope.value = true;
          disabledCreateProgram.value = false;
        } else {
          gptLoading.value = true;
          const prompt = await generateAIPrompt(props.website);
          if (prompt) {
            customScope.value = prompt.replace(/"/g, '');
            console.log('is sponsor', isSponsor);
            if (!user?.value) {
              console.log('local', customScope.value);
              localStorage.setItem('customSelectedScope', customScope.value);
            }
            editingCustomScope.value = true;
            disabledCreateProgram.value = false;
            console.log('prosp', props.value);
          }
        }
      } catch (err) {
        console.error(err);
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to generate prompt',
          isShowSnackbar: true
        });
      } finally {
        gptLoading.value = false;
      }
    };

    const trimCustomScope = () => {
      if (customScope.value) {
        customScope.value = customScope.value.trim();
      }
    };

    /** --- BEGIN LIFECYCLE HOOKS --- */
    onMounted(() => {
      window.addEventListener('resize', () => breakpoint.value.update());
      requestAnimationFrame(() => {
        scope__containerRef.value.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      });
      selectScope(customScope.value);

      const isSponsorRoute =
        root.$route.path.startsWith('/sponsor/') && root.$route.params?.shareCode;
      if (isSponsorRoute) {
        const programSettings = localStorage.getItem('programSettings');
        if (programSettings) {
          const { insertCustomScope, customScopeText } = JSON.parse(programSettings);
          if (insertCustomScope) {
            isShareCode.value = true;
            Object.keys(customScopeText).forEach(key => {
              const value = customScopeText[key];
              if (value) {
                dialogInputs.value[key] = value;
                savedInputs.value[key] = value;
                savedInputsFlags.value[key] = true;
              } else {
                savedInputsFlags.value[key] = false;
              }
            });
          }
          makeScopeWithChatGpt(true);
        }
      } else {
        // if (localStorage.getItem('customSelectedScope')) {
        //   customScope.value = localStorage.getItem('customSelectedScope');
        // }
        isShareCode.value = false;
      }
      // editingCustomScope.value = true;
    });

    const uploadedFile = ref(null);
    const uploading = ref(false);

    const handleFileUpload = async file => {
      if (!file) return;
      uploadedFile.value = file;
    };

    const handleSaveUpload = async () => {
      if (!uploadedFile.value) return;

      try {
        uploading.value = true;

        // Store the file name
        savedInputs.value.upload = uploadedFile.value.name;
        localStorage.setItem('scope_upload', uploadedFile.value.name);

        // Here you would typically handle the actual file upload
        // For example:
        // const formData = new FormData();
        // formData.append('file', uploadedFile.value);
        // await axios.post('/api/upload', formData);

        closeDialog('upload');
        uploadedFile.value = null;

        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'success',
          message: 'File uploaded successfully!',
          isShowSnackbar: true
        });
      } catch (error) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to upload file',
          isShowSnackbar: true
        });
      } finally {
        uploading.value = false;
      }
    };

    const finishEditing = () => {
      selectScope(customScope.value);
      editingCustomScope.value = false;
    };

    const quickFinish = async () => {
      finishEditing();
      await submitScope();
    };

    const loading = ref(false);
    const saveNameDialog = ref(false);
    const saveName = ref('');
    const saveEmail = ref('');
    const effectiveUserId = computed(() => generateDeviceId());

    const saveToAirtable = async () => {
      try {
        saveName.value = `Save from ${new Date().toLocaleDateString()}`;
        saveNameDialog.value = true;
      } catch (err) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to save customizations',
          isShowSnackbar: true
        });
      }
    };

    const confirmSave = async () => {
      try {
        loading.value = true;
        // Create a single scope object from current scope
        const singleScope = {
          id: generateDeviceId(), // Use as unique ID
          website: props.website,
          scope: customScope.value
        };

        const saveData = {
          userId: effectiveUserId.value,
          email: saveEmail.value,
          deviceInfo: getDeviceInfo(),
          savedAt: new Date().toISOString(),
          savedInputs: savedInputs.value,
          scopesList: [singleScope], // Add as single item array
          scopesPerEmployer: 1, // Always 1 for single scope
          selectedScopes: [singleScope], // Same as scopesList for single scope
          name: saveName.value
        };

        await saveScopesToAirtable(saveData);
        saveNameDialog.value = false;

        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'success',
          message: 'Saved customizations successfully',
          isShowSnackbar: true
        });
      } catch (err) {
        console.error('Save error:', err); // Add error logging
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to save customizations',
          isShowSnackbar: true
        });
      } finally {
        loading.value = false;
      }
    };

    const loadDialog = ref(false);
    const savedScopes = ref<SavedScope[]>([]);

    const loadFromAirtable = async () => {
      try {
        loading.value = true;
        const saves = await loadScopesFromAirtable(effectiveUserId.value);

        if (!saves || saves.length === 0) {
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            type: 'info',
            message: 'No saved customizations found',
            isShowSnackbar: true
          });
          return;
        }

        // Filter saves to only show single-scope saves
        savedScopes.value = saves.filter(save => {
          // Check if this is a single-scope save
          const isSingleScope = save.scopesList?.length === 1;

          // Check if save has a scope for current website
          const hasMatchingScope = save.scopesList?.some(scope => scope?.website === props.website);

          // Only show saves that:
          // 1. Are single-scope saves AND have matching website
          // 2. OR have only customization inputs (no scopes)
          return (
            (isSingleScope && hasMatchingScope) ||
            (save.savedInputs && (!save.scopesList || save.scopesList.length === 0))
          );
        });

        if (savedScopes.value.length === 0) {
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            type: 'info',
            message: 'No saved customizations found for this website',
            isShowSnackbar: true
          });
          return;
        }

        loadDialog.value = true;
      } catch (err) {
        console.error('Load error:', err);
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to load customizations',
          isShowSnackbar: true
        });
      } finally {
        loading.value = false;
      }
    };

    const loadSelectedSave = (save: SavedScope) => {
      try {
        // Load saved inputs if they exist
        if (save.savedInputs) {
          Object.entries(save.savedInputs).forEach(([key, value]) => {
            if (value && Object.prototype.hasOwnProperty.call(savedInputs.value, key)) {
              savedInputs.value[key] = value;
              localStorage.setItem(`scope_${key}`, value);
            }
          });
        }

        // Find scope for current website
        const matchingScope = save.scopesList?.find(scope => scope?.website === props.website);

        // Only update scope if we found a matching one
        if (matchingScope?.scope) {
          customScope.value = matchingScope.scope;
          emit('input', matchingScope.scope);
        }

        loadDialog.value = false;
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'success',
          message: 'Loaded customizations successfully',
          isShowSnackbar: true
        });
      } catch (err) {
        console.error('Load selected save error:', err);
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to load selected customization',
          isShowSnackbar: true
        });
      }
    };

    // Update the format description to be more specific
    const formatSaveDescription = (save: SavedScope) => {
      if (!save.scopesList || save.scopesList.length === 0) {
        return 'Customization settings only';
      }
      return save.scopesList.length === 1
        ? 'Single scope customization'
        : 'Multiple scope customization';
    };

    return {
      createProgramDialog,
      editingCustomScope,
      breakpoint,
      displayScopes,
      tileHeightRate,
      customScope,
      customScopeTitle,
      customScopeDetails,
      submitScope,
      selectScope,
      loadingDataValue,
      loadingData,
      editScope,
      scopeRefs,
      customScopeRef,
      scope__containerRef,
      dialogs,
      dialogInputs,
      openDialog,
      closeDialog,
      saveDialog,
      savedInputs,
      clearDialog,
      clearAllDialog,
      clearAll,
      hasAnySavedInputs,
      trimCustomScope,
      uploadedFile,
      uploading,
      handleFileUpload,
      handleSaveUpload,
      makeScopeWithChatGpt,
      finishEditing,
      quickFinish,
      isShareCode,
      savedInputsFlags,
      // loading,
      saveNameDialog,
      saveName,
      saveEmail,
      saveToAirtable,
      confirmSave,
      loadFromAirtable,
      loadDialog,
      savedScopes,
      loadSelectedSave,
      formatSaveDescription,
      gptLoading,
      hasUserSavedInputs,
      disabledCreateProgram
    };
  }
});
