import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#4F4F4F', // primary
        secondary: '#FFFFFF', // white
        accent: '#BDBDBD', // grey
        error: '#EA6763', // red
        info: '#3C9CCC', // blue
        success: '#6EBA7F', // green
        warning: '#FDD35A', // yellow
        pink: '#eda1bf', // pink
        purple: '#ae90b0', // purple
        red: '#ea6764', // red
        blue: '#3c9dcd', // blue
        green: '#6eba80', // green
        yellow: '#fec34b', // yellow
        orange: '#f79961', // orange
        darkpink: '#8f5f77', // 40% darker pink
        darkpurple: '#6d5a6f', // 40% darker purple
        darkred: '#8f3b38', // 40% darker red
        darkblue: '#24648a', // 40% darker blue
        darkgreen: '#3b6b48', // 40% darker green
        darkyellow: '#b3861e', // 40% darker yellow
        darkorange: '#a65e34', // 40% darker orange
        lightpink: '#f9d6e3', // 40% lighter pink
        lightpurple: '#d8c3d4', // 40% lighter purple
        lightred: '#f8b8b0', // 40% lighter red
        lightblue: '#8cc8eb', // 40% lighter blue
        lightgreen: '#a8e0b8', // 40% lighter green
        lightyellow: '#ffec9f', // 40% lighter yellow
        lightorange: '#ffc4a3', // 40% lighter orange
        lightgrey: '#dedede' // 40% lighter grey
      }
    }
  }
});
